import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from 'primeng/dragdrop';
import { ListboxChangeEvent, ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { SLTableColumnDef } from '@@shared/sl-table/models/sl-table.model';

@Component({
	selector: 'sl-table-settings',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MatTooltipModule, OverlayPanelModule, ListboxModule, FormsModule, MatIconModule, DragDropModule],
	templateUrl: './table-settings.component.html',
	styleUrl: './table-settings.component.scss'
})
export class TableSettingsComponent<Req, Res> implements OnInit {

	columnsSignal$ = input<SLTableColumnDef<Req, Res>[]>([], { alias: 'columns' });
	stickyColumnsSignal$ = input<boolean>(false, { alias: 'stickyColumns' });
	columnsOrderingSignal$ = input<boolean>(false, { alias: 'columnsOrdering' });
	showSearchSignal$ = input<boolean>(true, { alias: 'showSearch' });
	showToggleAllSignal$ = input<boolean>(true, { alias: 'showToggleAll' });

	readonly columnsChange = output<SLTableColumnDef<Req, Res>[]>();

	displayItems: { label: string; colDef: SLTableColumnDef<Req, Res> }[];
	selectedColumns: SLTableColumnDef<Req, Res>[] = [];
	selectAll: boolean = false;
	draggedColumnOrder: number | null = null;

	readonly #cdr = inject(ChangeDetectorRef);

	ngOnInit(): void {
		this.#initializeDisplayItems();
	}

	onSelectAllChange(event: { originalEvent: Event; checked: boolean }): void {
		this.columnsSignal$().forEach((item: SLTableColumnDef<Req, Res>) => item.isVisible = event.checked);
		this.selectedColumns = event.checked ? [...this.columnsSignal$()] : [];
		this.selectAll = event.checked;
		this.emitChanges();
	}

	emitChanges(): void {
		this.columnsChange.emit(this.displayItems.map((item) => item.colDef));
	}

	onChange(event: ListboxChangeEvent): void {
		const { originalEvent, value } = event;
		originalEvent.stopPropagation();
		if (value) {
			this.selectAll = value.length === this.columnsSignal$().length;
		}
		this.columnsSignal$().forEach(item => {
			item.isVisible = this.selectedColumns.includes(item);
		});
		this.#cdr.detectChanges();
		this.emitChanges();
	}

	onDrop(droppedOrder: number): void {
		if (!this.columnsOrderingSignal$()) {
			return;
		}
		if (this.draggedColumnOrder !== null && this.draggedColumnOrder !== droppedOrder) {
			// Find the dragged and dropped columns in displayItems
			const draggedItemIndex = this.displayItems.findIndex(item => item.colDef.order === this.draggedColumnOrder);
			const droppedItemIndex = this.displayItems.findIndex(item => item.colDef.order === droppedOrder);

			if (draggedItemIndex > -1 && droppedItemIndex > -1) {
				// Swap orders in displayItems
				[this.displayItems[draggedItemIndex], this.displayItems[droppedItemIndex]] =
					[this.displayItems[droppedItemIndex], this.displayItems[draggedItemIndex]];

				// Also update the order in the original items array
				[this.columnsSignal$()[draggedItemIndex].order, this.columnsSignal$()[droppedItemIndex].order] =
					[this.columnsSignal$()[droppedItemIndex].order, this.columnsSignal$()[draggedItemIndex].order];

				// Emit the updated items array
				this.emitChanges();
			}
		}
		this.draggedColumnOrder = null;
		this.#cdr.detectChanges();
	}

	dragStart(order: number): void {
		if (!this.columnsOrderingSignal$) {
			return;
		}
		this.draggedColumnOrder = order;
		this.#cdr.detectChanges();
	}

	toggleStickiness(col: SLTableColumnDef<Req, Res>, $event: Event): void {
		$event.stopPropagation();
		col.isSticky = !col.isSticky;
		this.emitChanges();
		this.#cdr.detectChanges();
	}

	#initializeDisplayItems(): void {
		const sortedItems = [...this.columnsSignal$()].sort((a, b) => a.order - b.order);
		this.displayItems = sortedItems.filter(item => !item.isDisabled).map(item => ({
			label: item.header.displayName,
			colDef: item
		}));
		this.selectedColumns = sortedItems.filter(item => item.isVisible && !item.isDisabled);
		this.#cdr.detectChanges();
	}
}
