<p-overlayPanel #op
	showTransitionOptions="200ms"
	hideTransitionOptions="200ms"
	class="toggle-column-visibility"
	styleClass="filter-panel-table"
	[dismissable]="true"
	[showCloseIcon]="true"
	[focusOnShow]="true">
		<div class="header-wrapper mb-sm sl-flexbox sl-flexbox-align-center sl-flexbox-justify-space-between">
			<span class="sl-typography-body-title">Manage columns</span>
		</div>
		<p-listbox
			styleClass="new-design no-border sl-v1 no-filter-border mb-sm"
			[options]="displayItems"
			optionLabel="label"
			optionValue="colDef"
			[(ngModel)]="selectedColumns"
			[filter]="showSearchSignal$()"
			filterPlaceHolder="Search..."
			[multiple]="true"
			[checkbox]="true"
			[showToggleAll]="showToggleAllSignal$()"
			[metaKeySelection]="false"
			(onSelectAllChange)="onSelectAllChange($event)"
			(onChange)="onChange($event)">
			<ng-template let-item pTemplate="item">
				<div class="sl-typography-body sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-space-between list-item-wrapper"
					pDraggable
					pDroppable
					[pDroppableDisabled]="!columnsOrderingSignal$()"
					(onDrop)="onDrop(item.colDef.order)"
					(onDragStart)="dragStart(item.colDef.order)">
					<div [class.drag-handle]="columnsOrderingSignal$()" class="sl-typography-body-medium">{{ item.label }}</div>
					<mat-icon *ngIf="stickyColumnsSignal$()" (click)="toggleStickiness(item.colDef, $event)"
						class="icon material-icons-outlined mat-icon-16">
						{{ item.colDef.isSticky ? 'lock' : 'lock_open' }}</mat-icon>
				</div>
			</ng-template>
		</p-listbox>
	</p-overlayPanel>
	<span matTooltipPosition="above" matTooltip="Manage columns">
	<mat-icon class="material-symbols-outlined manage-icon clickable" (click)="op.toggle($event)">view_week</mat-icon>
</span>
